<script lang="ts" setup>

  // Currency Store
  const currencyStore = useCurrencyStore()
  const { currency } = storeToRefs(currencyStore)

  // Config
  const runtimeConfig = useRuntimeConfig()

  // Idioma
  const { locale, locales, setLocaleCookie } = useI18n()
  const switchLocalePath = useSwitchLocalePath()
  useHead({ // añadir idioma al documento
    htmlAttrs: { lang: locale.value.substring(0, 2) },
  })

  const selectedIdioma = ref(locale.value)
  setLocaleCookie(locale.value)
  watch(selectedIdioma, (value) => { // reload en cambio de idioma
    reloadNuxtApp({
      path: switchLocalePath(value)
    })
  },{ immediate: false })

  // Strapi
  const strapiData = await getStrapiData('global', {
    populate: [
      "header.logo",
      "header.destinos",
      "header.buttonDestinos",
      "header.buttonFunciona",
      "header.buttonPartner",
      "header.buttonAyuda",
      "header.buttonPerfil",
      "header.buttonTarjeta"
    ],
    locale: locale.value
  })
 const strapiContent = strapiData?.header

  // Moneda
  const { data: dataMonedas } = await useLazyFetch<{ data: Currency[] }>(`${useBaseUrl()}/currencies`, {
    headers: { "Content-Type": "application/json" },
    timeout: 5000, // esperar 5 segundos
    query: {
      locale
    }
  })

  // Menu
  const open = ref(false)

  // Select agencias
  const arrayColabora = strapiContent?.buttonPartner.map((opcion: StrapiButton) => ({
    label: opcion?.label,
    slot: 'colabora',
    click: () => {
      navigateTo(opcion?.link, {
        external: true,
        open: {
          target: '_blank'
        }
      })
    }
  })) || []
  const colaboraSelect = [arrayColabora]

  // Select ayuda
  const arrayAyuda = Array.isArray(strapiContent?.buttonAyuda) ? strapiContent.buttonAyuda.map((opcion: StrapiButton) => ({
    label: opcion?.label,
    slot: 'ayuda',
    click: () => {
      navigateTo(opcion?.link, {
        external: true,
        open: {
          target: '_blank'
        }
      })
    }
  })) : []
  const ayudaSelect = [arrayAyuda]

  // Color header
  const isScrolledHeader = ref(false) // detectar el scroll para cambiar color texto header
  onMounted(() => {
    const checkScrollHeader = () => {
      const height = window.innerHeight - 80 - 16 // innerHeight - 80 del hero y 16px del Header
      isScrolledHeader.value = window.scrollY > height
    }
    window.addEventListener('scroll', checkScrollHeader)
  })

  // Scroll Store
  const scroll = useScrollStore()
  const { isScrolledInput } = storeToRefs(scroll)

  // Home Store
  const { isHome } = useIsHome()

  // User data
  const { data: dataUser } = await useLazyFetch<UserData>(`/api-web/user-data`, {
    headers: { "Content-Type": "application/json" },
    timeout: 5000, // esperar 5 segundos
    query: { 
      locale 
    }
  })

</script>

<template>
  <header class="top-0 left-0 right-0 w-full h-16 z-50 backdrop-blur-lg transition shadow-sm sticky bg-white/80">
    <div class="px-5 py-4 mx-auto flex justify-between items-center h-full" :class="`${ isHome ? 'max-w-screen-2xl' : 'max-w-screen-2xl'}`">
      <div class="flex items-center">
        <!-- Logo -->
        <div class="min-w-16">
          <NuxtLink aria-label="Home" :to="`/${locale}`">
            <NuxtImg :provider="strapiContent?.logo?.data?.attributes?.provider" preload alt="Logo Byhours" :src="getImageSrc(strapiContent?.logo?.data, '')" width="90" :class="['transition']" />
          </NuxtLink>
        </div>

        <!-- Inputs -->
        <div :class="['px-4', 'xl:flex']" v-if="(isScrolledInput && isHome) || !isHome">
          <SearchInput />
        </div>
      </div>

      <div class="gap-4 items-center justify-end hidden xl:flex" :class="{ 'w-7/12': isScrolledInput && isHome}">

        <!-- Destinos -->
        <UPopover>
          <UButton size="xs" aria-label="Destinos" color="black" variant="link" :class="['hover:no-underline']" :label="strapiContent?.tituloDestinos" trailing-icon="i-heroicons-chevron-down-20-solid" />
          <template #panel="{ close }">
            <div class="p-5 w-96 dark:bg-gray-800">
              <p class="text-sm font-bold mb-2 text-gray-400 pl-2">{{ strapiContent?.labelDestinos.toUpperCase() }}</p>
              <ul class="grid grid-cols-2">
                <NuxtLink
                  v-for="ciudad in strapiContent?.destinos?.slice(0, 10)"
                  :aria-label="ciudad.label"
                  class="w-full text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-900 p-2 rounded-md text-sm"
                  :to="`/${locale}/${ciudad.link}-${ciudad.key}d.html`"
                  @click="close"
                >
                  <li>{{ ciudad.label }}</li>
                </NuxtLink>
              </ul>
              <div class="flex w-full justify-center">
                <UButton
                  block
                  variant="outline"
                  class="mt-5"
                  color="black"
                  :ui="{
                    rounded: 'rounded-full',
                    padding: {
                      sm: 'px-6',
                    }
                  }"
                  :to="getButtonUrl(strapiContent?.buttonDestinos)"
                  target="_self"
                  :aria-label="strapiContent?.buttonDestinos"
                  size="sm"
                  :label="strapiContent?.buttonDestinos?.label"
                  @click="close"
                >
                </UButton>
              </div>
            </div>
          </template>
        </UPopover>
        
        <!-- ¿Cómo funciona? -->
        <UButton size="xs" :class="['hover:no-underline']" color="black" :to="getButtonUrl(strapiContent?.buttonFunciona)" :aria-label="strapiContent?.buttonFunciona?.label" variant="link" :label="strapiContent?.buttonFunciona?.label" />

        <!-- Colabora -->
        <UDropdown :ui="{ padding: 'p-2' }" :items="colaboraSelect" :popper="{ placement: 'bottom-start' }">
          <UButton size="xs" :class="['hover:no-underline']" variant="link" color="black" :label="strapiContent?.labelPartners" trailing-icon="i-heroicons-chevron-down-20-solid" />
        </UDropdown>

        <!-- Ayuda -->
        <UDropdown :ui="{ padding: 'p-2' }" :items="ayudaSelect" :popper="{ placement: 'bottom-start' }">
          <UButton size="xs" :class="['hover:no-underline']" variant="link" color="black" :label="strapiContent?.labelAyuda" trailing-icon="i-heroicons-chevron-down-20-solid" />
        </UDropdown>
        
        <!-- Idioma / Moneda -->
        <UPopover>
          <UButton size="xs" :class="['hover:no-underline']" aria-label="Idioma y moneda" variant="link" color="black" :label="`${selectedIdioma.toUpperCase()} | ${currency}`" />
          <template #panel>
            <div class="flex flex-col gap-2 p-5 pt-3 dark:bg-gray-800">
              <div>
                <p class="text-gray-400 text-sm mb-1">{{ strapiContent?.tituloIdioma }}</p>
                <USelect size="md" v-model="selectedIdioma" :options="locales" value-attribute="code" option-attribute="name"/>
              </div>
              <div>
                <p class="text-gray-400 text-sm mb-1">{{ strapiContent?.tituloMoneda }}</p>
                <div>
                  <USelect size="md" v-model="currency" :options="dataMonedas?.data" option-attribute="name" value-attribute="id" />
                </div>
              </div>
            </div>
          </template>
        </UPopover>

        <!-- User zone -->
        <UButton
          dynamic
          :to="getButtonUrl(strapiContent?.buttonPerfil)"
          target="_blank"
          aria-label="Usuario"
          icon="i-heroicons-user-circle-solid"
          :label="dataUser?.data?.name ?? ''"
          :size="dataUser?.data ? 'xs' : 'sm'"
          color="black"
          :variant="dataUser?.data ? 'solid' : 'link'"
          class="hover:no-underline -mt-0.5"
        />
      </div>

      <!-- Menu plegado -->
      <div class="xl:hidden">
        <UButton
          aria-label="Desplegar menú"
          @click="open = true"
          icon="i-heroicons-bars-2-20-solid"
          color="white"
          variant="link"
        />
        <UModal v-model="open" fullscreen :transition="false">
          <UCard
            :ui="{
              base: '',
              rounded: '',
              divide: 'divide-y divide-gray-100 dark:divide-gray-800',
              body: {
                base: 'grow'
              },
              ring: '',
              shadow: ''
            }"
          >
            <template #header>
              <div class="flex items-center justify-between mx-auto px-1">
                <!-- Logo -->
                <NuxtLink>
                  <NuxtImg :provider="strapiContent?.logo?.data?.attributes?.provider" preload alt="Logo Byhours" :src="getImageSrc(strapiContent?.logo?.data, '')" width="70" :class="['transition', {'invert': !isScrolledHeader && isHome && !open}]" />
                </NuxtLink>
                <UButton aria-label="Logo" color="gray" variant="ghost" square icon="i-heroicons-x-mark-20-solid" class="-my-1" @click="open = false" />
              </div>
            </template>
            <UAccordion :items="[{ icon: 'i-heroicons-map-pin', slot: 'destinos'}]" :label="strapiContent?.tituloDestinos" color="gray" variant="ghost" size="md">
              <template #destinos>
                <div class="text-gray-900 dark:text-white ml-5 border-l">
                  <ul class="grid grid-cols-2 pl-5">
                    <NuxtLink
                      v-for="ciudad in strapiContent?.destinos"
                      :aria-label="ciudad.label" 
                      class="w-full text-gray-700 dark:text-gray-50 hover:bg-gray-50 dark:hover:bg-gray-900 p-2 rounded-md text-sm" 
                      :to="`/${locale}/${ciudad.link}-${ciudad.key}d.html`"
                      @click="open = false"
                    >
                      <li>{{ ciudad.label }}</li> 
                    </NuxtLink>
                  </ul>
                </div>
                <div class="flex w-full justify-center">
                  <UButton
                    block
                    variant="outline"
                    class="mt-5"
                    color="black"
                    :ui="{
                      rounded: 'rounded-full',
                      padding: {
                        sm: 'px-6',
                      }
                    }"
                    :to="getButtonUrl(strapiContent?.buttonDestinos)"
                    target="_self"
                    :aria-label="strapiContent?.buttonDestinos"
                    size="sm"
                    :label="strapiContent?.buttonDestinos?.label"
                    @click="open = false"
                  >
                  </UButton>
                </div>
              </template>
            </UAccordion>
            <UButton
              icon="i-heroicons-question-mark-circle"
              size="md"
              color="gray"
              variant="ghost"
              :aria-label="strapiContent?.buttonFunciona?.label"
              :label="strapiContent?.buttonFunciona?.label"
              :to="getButtonUrl(strapiContent?.buttonFunciona)"
              class="w-full mb-2"
              @click="open = false"
            />
            <UAccordion dynamic :items="[{
                label: strapiContent?.labelPartners,
                icon: 'i-heroicons-chat-bubble-left-right',
                slot: 'colabora'
              }]"
              color="gray"
              variant="ghost"
              size="md">
              <template #colabora>
                <div class="grid text-gray-900 dark:text-white ml-5 border-l">
                  <ul class="pl-5">
                    <li v-for="(colabora) in strapiContent?.buttonPartner" class="p-2">
                      <NuxtLink :to="getButtonUrl(colabora)" target="_blank">{{ colabora.label }}</NuxtLink>
                    </li>
                  </ul>
                </div>
              </template>
            </UAccordion>
            <UAccordion dynamic :items="[{
                label: strapiContent?.labelAyuda,
                icon: 'i-heroicons-chat-bubble-left-ellipsis',
                slot: 'colabora'
              }]"
              color="gray"
              variant="ghost"
              size="md">
              <template #colabora>
                <div class="grid text-gray-900 dark:text-white ml-5 border-l">
                  <ul class="pl-5">
                    <li v-for="(ayuda) in strapiContent?.buttonAyuda" class="p-2">
                      <NuxtLink :to="getButtonUrl(ayuda)" target="_blank">{{ ayuda.label }}</NuxtLink>
                    </li>
                  </ul>
                </div>
              </template>
            </UAccordion>
            <UDivider class="py-2" />
            <div class="flex flex-col gap-2 pt-2 pl-3 pb-4">
              <div>
                <p class="text-gray-500 text-sm mb-1">{{ strapiContent?.tituloIdioma }}</p>
                <USelect size="xl" v-model="selectedIdioma" :options="locales" value-attribute="code" option-attribute="name"/>
              </div>
              <div>
                <p class="text-gray-500 text-sm mb-1">{{ strapiContent?.tituloMoneda }}</p>
                <div>
                  <USelect size="xl" v-model="currency" :options="dataMonedas?.data" option-attribute="name" value-attribute="id" />
                </div>
              </div>
            </div>
            <UDivider class="py-2" />
            <UButton
              dynamic
              :to="getButtonUrl(strapiContent?.buttonPerfil)"
              aria-label="Usuario"
              target="_blank"
              icon="i-heroicons-user-circle-solid"
              size="md"
              color="gray"
              variant="ghost"
              :label="dataUser?.data?.name ?? strapiContent?.buttonPerfil?.label"
              class="w-full mb-2"
            />
          </UCard>
        </UModal>
      </div>
    </div>
  </header>
</template>